<template>
  <div class="home">
    <WaBtn />
    <!-- header  -->
    <div class="fixed z-10 w-full py-5 shadow-md bg-brown-1">
      <div class="container">
        <div class="flex items-center justify-between">
          <img src="/images/main-logo.png" alt="" class="w-32" />
          <div>
            <a href="tel:+601139946364" class="lg:hidden">
              <img src="/images/phone.png" alt="" class="w-12" />
            </a>
            <p
              class="hidden text-xl font-light leading-tight text-right lg:block"
            >
              Call Us <br />
              <a
                href="tel:+601139946364"
                class="block transition duration-200 transform hover:text-purple-1 hover:scale-110"
                >011-3994 6364</a
              >
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="h-28 md:"></div>
    <!-- banner -->
    <MainBanner />

    <!-- why choose us -->
    <div class="bg-black py-14">
      <div class="container">
        <h1 class="text-2xl font-bold text-center text-white">Why Choose Us</h1>
        <p
          class="pt-4 text-sm leading-tight text-center text-white lg:w-3/4 lg:mx-auto lg:text-base"
        >
          We solved over 10,000 customer's problem. Aitsulab promise will give
          the best services provide to our supporting customers.
        </p>
        <div class="pt-8 lg:pt-10 md:flex md:flex-wrap">
          <div
            v-for="(item, i) in choose_us"
            :key="i"
            class="pb-4 text-center md:px-2 md:w-1/2 lg:w-1/4"
          >
            <img :src="item.image" :alt="item.alt" class="w-32 mx-auto" />
            <div class="pt-4 text-white">
              <h3 class="text-lg font-semibold text-purple-1">
                {{ item.alt }}
              </h3>
              <p class="pt-2 text-sm leading-tight">{{ item.p }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="py-10 bg-black">
      <div class="container">
        <h1 class="text-2xl font-bold text-center text-brown-1">
          ERGONOMIC STUDY
        </h1>
        <p class="pt-2 text-sm leading-tight text-center text-white">
          In order to better apply the concept of ergonomics to the seat, Sihoo
          has conducted in-depth research and creation on factors such as
          sitting posture and pressure over the years, in order to achieve the
          best balance between the seat and the person.
        </p>
      </div>
      <div class="px-4 pt-7 lg:pt-10 xl:container">
        <div
          v-for="(item, i) in study"
          :key="i"
          class="pb-5 md:pb-8 md:flex md:items-center"
        >
          <div class="md:w-1/2" :class="item.order">
            <img
              :src="item.image"
              :alt="item.alt"
              class="lg:w-3/4 lg:mx-auto"
            />
          </div>
          <div class="pt-4 md:w-1/2" :class="item.padding">
            <h3
              class="text-lg font-semibold leading-tight text-white md:text-2xl"
            >
              {{ item.alt }}
            </h3>
            <p class="pt-3 text-sm text-white">{{ item.p }}</p>
          </div>
        </div>
      </div>
    </div>

    <!-- sub banner -->
    <div>
      <img src="/images/bg-sub.jpg" alt="" class="hidden md:block">
      <img src="/images/bg-sub-mobile.jpg" alt="" class="md:hidden">
    </div>

    <!-- product  -->
    <div id="product"
      class="py-10 bg-top bg-no-repeat bg-cover"
      style="background-image: url('/images/bg-product.jpg')"
    >
      <div class="container">
        <div class="md:flex md:flex-wrap">
          <div
            v-for="(item, i) in product"
            :key="i"
            class="relative pb-4 md:px-2 md:w-1/2 lg:w-1/3"
          >
            <img :src="item.image" :alt="item.alt" />
            <!-- <div class="absolute z-0 top-2 left-2">
              <img
              :src='item.img_link'
              alt=""
              class="w-28"
              />
            </div> -->
            <div class="pt-4">
              <div class="w-4/5 mx-auto text-center bg-white md:w-full">
                <h3 class="py-2 text-xl font-semibold text-white bg-gray-1">
                  {{ item.alt }}
                </h3>
                <div class="py-4">
                  <!-- <p class="font-medium line-through md:text-lg text-purple-1">
                    {{ item.old_price }}
                  </p> -->
                  <p class="text-3xl font-bold text-purple-1">
                    {{ item.price }}
                  </p>
                  <p>WITH PEDAL : {{ item.price_pedal }}</p>
                </div>
                <div
                  class="px-4 pt-4 space-y-2 text-xs font-light leading-tight text-gray-600 hCustom"
                >
                  <p
                    v-for="desc in item.list"
                    :key="desc"
                    class="pb-3 border-b border-gray-200"
                  >
                    {{ desc }}
                  </p>
                </div>
                <div class="py-6">
                  <p
                    class="w-40 py-1 mx-auto text-white transition duration-200 transform hover:scale-110 bg-purple-1"
                  >
                    <a href="https://wa.me/601139946364">Get A Quote Now</a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- testimonial  -->
    <div class="py-10 bg-black">
      <div class="container">
        <h1
          class="text-2xl font-bold text-center text-white uppercase md:text-4xl"
        >
          It's <span class="text-purple-1">REALLY more affordable</span> than
          you think.
        </h1>
        <p
          class="pt-3 text-xl font-semibold text-center text-white md:text-2xl"
        >
          Clients' Review 
        </p>

        <div class="pt-8 xl:max-w-4xl xl:mx-auto">
          <carousel
            :autoplay="true"
            :loop="true"
            :responsive="{
              0: { items: 1, nav: false, dots: true },
              768: { items: 2, nav: false, dots: false, loop: false },
            }"
          >
            <div
              v-for="(item, i) in review"
              :key="i"
              class="mx-2 border rounded border-brown-1"
            >
              <div
                class="flex items-center justify-between p-5 border-b border-brown-1"
              >
                <div>
                  <h1 class="font-medium leading-tight text-white">
                    {{ item.alt }}
                  </h1>
                  <div class="text-yellow-300">
                    <i class="fa-solid fa-star"></i>
                    <i class="fa-solid fa-star"></i>
                    <i class="fa-solid fa-star"></i>
                    <i class="fa-solid fa-star"></i>
                    <i class="fa-solid fa-star"></i>
                  </div>
                </div>
                <img
                  :src="item.image"
                  :alt="item.alt"
                  class="rounded-full icon-badge"
                />
              </div>
              <div class="px-4 py-8 md:h-48">
                <p class="text-sm font-light leading-tight text-white">
                  {{ item.p }}
                </p>
              </div>
            </div>
          </carousel>
        </div>
      </div>
    </div>

    <!-- youtube link  -->
    <div class="py-10">
      <div class="container">
        <div class="md:flex md:space-x-2 lg:max-w-5xl lg:mx-auto">
          <div class="pb-3 md:pb-0 md:w-1/2">
            <iframe
              width="100%"
              height="315"
              src="https://www.youtube.com/embed/Mh_pa860XFw"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </div>
          <div class="md:w-1/2">
            <iframe
              width="100%"
              height="315"
              src="https://www.youtube.com/embed/CVEjyshsz8I"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </div>
        </div>
      </div>
    </div>

    <!-- about us -->
    <div
      class="py-10 bg-center bg-no-repeat bg-cover lg:bg-top lg:py-20 xl:bg-center"
      style="background-image: url('/images/bg-about.jpg')"
    >
      <div class="container">
        <div class="md:w-3/4 lg:w-1/2 md:ml-auto">
          <div class="p-5 bg-brown-1 bg-opacity-80">
            <h3 class="text-xl font-bold text-gray-1">About Us</h3>
            <div class="pt-3 space-y-2 text-sm leading-tight text-gray-600">
              <p>
                AITSULAB was established in 2017 in Malaysia. We provide various
                brands of ergonomic chairs to customers that have issues when
                sitting. In 5 years, we had solved over 10,000 customer’s
                problems.
              </p>
              <p>
                The founding of AITSULAB started from Alex, founder of AITSULAB.
                As a freelance graphic designer, he had to sit in front of
                computer up to 10 hours a day. Back pain, shoulder pain, and
                neck pain had become a big issue to him. So, Alex decided to buy
                an ergonomic chair. After sitting for a month, all his pain
                issues were relieved. So, he decided to introduce ergonomic
                chair to everyone by starting this business, which is AITSULAB.
              </p>
            </div>
            <h3 class="pt-5 text-xl font-bold text-gray-1">Our Mission</h3>
            <p class="pt-3 space-y-2 text-sm leading-tight text-gray-600">
              We are dedicated to provide the best ergonomic comfort to all our
              customers
            </p>
          </div>
        </div>
      </div>
    </div>

    <!-- faq & certificate  -->
    <div class="py-10">
      <div class="container">
        <div class="lg:flex lg:items-center">
          <div class="pb-4 lg:pb-0 lg:w-1/2">
            <h3 class="text-2xl font-bold capitalize text-gray-1">
              frequently asked questions
            </h3>
            <Accordion :faq="faq" :focus="false" class="pt-4 lg:pt-0" />
          </div>
          <div class="lg:w-1/2 lg:pl-4">
            <img
              src="/images/certificate.jpg"
              alt=""
              class="md:w-2/3 lg:w-full xl:w-4/5 md:mx-auto"
            />
          </div>
        </div>
      </div>
    </div>

    <!-- footer  -->
    <div
      id="element"
      class="py-10 bg-center bg-no-repeat bg-cover"
      style="background-image: url('/images/bg-enquiry.jpg')"
    >
      <div class="container lg:flex lg:items-center">
        <div class="lg:w-1/2 lg:order-2">
          <!-- enquiry form  -->
          <div class="p-6 bg-black bg-opacity-60 rounded-xl">
            <h1
              class="text-3xl font-bold leading-tight text-white capitalize lg:text-4xl"
            >
              GET IN TOUCH WITH US
            </h1>
            <EnquiryForm class="pt-8" />
          </div>
        </div>
        <div class="pt-6 lg:w-1/2">
          <img
            src="/images/main-logo-white.png"
            alt=""
            class="mx-auto w-72 md:w-80"
          />
          <div class="pt-3 text-center text-white">
            <div class="pb-5">
              <h1 class="pb-2 text-lg font-bold text-green-1">
                Aitsulab Enterprise
              </h1>
              <h1 class="text-lg font-medium leading-tight md:text-xl">
                2383, Jalan E 3/9, Taman Ehsan, 52100 Kuala Lumpur, Wilayah
                Persekutuan Kuala Lumpur
              </h1>
              <div class="pt-4 font-medium">
                <p>
                  Call Us At :
                  <a
                    href="tel:+601139946364"
                    class="transition duration-200 ease-in-out transform hover:text-purple-1 hover:scale-110"
                  >
                    011-39946364</a
                  >
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="py-3 bg-black">
      <div class="container mx-auto">
        <h1 class="text-xs text-center text-white lg:text-sm">
          Website maintained by Activa Media. All Rights Reserved.
        </h1>
      </div>
    </div>
  </div>
</template>

<script>
import carousel from "vue-owl-carousel";
import Accordion from "@/components/Accordion.vue";
import EnquiryForm from "@/components/EnquiryForm.vue";
import MainBanner from "@/components/MainBanner.vue";
import WaBtn from "@/components/WaBtn.vue";

export default {
  name: "Home",
  components: {
    carousel,
    Accordion,
    EnquiryForm,
    MainBanner,
    WaBtn,
  },
  data() {
    return {
      choose_us: [
        {
          image: "/images/choose-1.png",
          alt: "COST-EFFECTIVE",
          p: "The Most Cost-effective Ergonomic Chair in Malaysia Market",
        },
        {
          image: "/images/choose-2.png",
          alt: "5 YEARS WARRANTY",
          p: "We Provide Warranty Up to 5 years For Our Ergonomic Chairs",
        },
        {
          image: "/images/choose-3.png",
          alt: "BUY NOW PAY LATER",
          p: "We Provide Instalment Up to 24 Months",
        },
        {
          image: "/images/choose-4.png",
          alt: "BEST SERVICES",
          p: "Free Shipping (WM), Free Installation (KV), Customer Service Fast Replies",
        },
      ],
      study: [
        {
          order: "order-2",
          padding: "pr-4",
          image: "/images/study-1.jpg",
          alt: "SITTING POSTURE IS CLOSELY RELATED TO HEALTH",
          p: "The spine in a healthy sitting posture is S-shaped, which can avoid uneven pressure on the intervertebral disc; however, in many cases, with the fatigue of the muscles, people will unconsciously lean forward, which increases the internal pressure of the intervertebral disc. Will cause lumbar spine strain.",
        },
        {
          padding: "pl-4",
          image: "/images/study-2.jpg",
          alt: "DESIGN WITH REFERENCE TO MEDICAL RESEARCH",
          p: "According to medical research, different sitting postures exert different forces on the spine, and lumbar spine health problems are also common negative effects of prolonged sitting. As the weak link of health, lumbar spine force is the key target of SIHOO's designers.",
        },
      ],
      product: [
        {
          image: "/images/product-3.png",
          // img_link:'https://cdn.hextom.com/badge_pic/usb/432417/92f7924f-2bdb-43cd-a8dd-f3d55eaefbcc.png',
          alt: "SIHOO M57",
          // old_price: "RM829",
          price: "RM879",
          price_pedal: "RM919",
          list: [
            "Full Mesh Design",
            "Headrest: 45-degree adjustment, lifting function (8cm)",
            "3D armrest can be adjusted front and back (7cm), left and right 36 degrees), up and down (7cm)",
            "Seat: Curved Surface Seat (Mesh Material)",
            "Backrest:  S-Shaped spine frame with (mesh material) ",
            "Lumbar Support: 2D Adjustable Lumbar Pillow",
            "Gas Lift: Certified SGS 3 level gas lift",
            "Back tilting: 3 Gears adjustment (110-126 degree)",
            "Aluminum alloy chair foot with silent PU castor",
            "Add on Pedal: PP Material",
          ],
          link: "https://aitsulabmy.com/collections/sihoo-best-seller-product/products/sihoo-m57",
        },
        {
          image: "/images/product-2.png",
          // img_link:'https://cdn.hextom.com/badge_pic/usb/432417/b8b120af-b401-4ef6-a1c2-94d667fba134.png',
          alt: "SIHOO M18",
          // old_price: "RM620",
          price: "RM659",
          price_pedal: "RM729",
          list: [
            "Headrest: 45-degree adjustment, lifting function (8cm)",
            "PU armrest surface, Height adjustment up to 7cm",
            "Seat: Curved Surface Seat (High Density Mold Foam)",
            "Backrest: S-Shaped spine frame (Mesh) ",
            "Lumbar Support: 2D Adjustable Lumbar Pillow ",
            "Back tilting: 3 Gears adjustment (110-126 degree)",
            "Aluminum alloy chair foot with silent PU castor",
            "Add on Pedal: Cushion Material",
          ],
          link: "https://aitsulabmy.com/collections/sihoo-best-seller-product/products/sihoo-m18",
        },
        {
          image: "/images/product-1.png",
          // img_link:'https://cdn.hextom.com/badge_pic/usb/432417/b8b120af-b401-4ef6-a1c2-94d667fba134.png',
          alt: "SIHOO V1",
          // old_price: "RM1499",
          price: "1499",
          price_pedal: "RM1599",
          list: [
            "Full Mesh Design",
            "Headrest: 60 Degree rotation, 5.5CM up and down.",
            "4D armrest: can be adjusted front and back (6cm), left and right(40 degree), up and down (8cm), in and out (2.5cm).",
            "Adjustable backrest height: adjust up and down 5 level (6cm) (Mesh)",
            "Adjustable seat (5cm Seat Depth Adjustable) (Mesh)",
            "Lumbar Support:: height 9cm adjustable, front and back adjustable (1.5cm)",
            "Gas Lift: Certified SGS 3 level gas lift",
            "Back tilting: 4 Gears adjustment (110-140 degree)",
            "Aluminum alloy chair foot with silent PU castor",
            "Add on Pedal: Leather Material",
          ],
          link: "https://aitsulabmy.com/collections/sihoo-best-seller-product/products/sihoo-v1",
        },
      ],
      review: [
        {
          image: "/images/client-1.jpg",
          alt: "Strawberry Meiyu (Influencer)",
          p: "Backache has been disturbing me as my job requires me to sit long hours in front of the PC. With a normal chair, my back pain got worst. But after i got to know AITSULAB, their ergonomic chair really helps a lot and it is very comfortable even i sat for long hours.",
        },
        {
          image: "/images/client-2.jpg",
          alt: "Noel 诺幺 (Influencer, Gamer)",
          p: " As a gamer, definitely i will need to have a good quality ergonomic chair to support my sitting posture. It is worth the price for their chair and i actually changed all my office’s chairs to their ergonomic seat.",
        },
      ],
      faq: [
        {
          q: "How Long does it take for my order to reach me ?",
          a: "We deliver your orders by HantarGo Express, taking 3-5 business days to reach your doorstep",
        },
        {
          q: "How does self-pickup work? Where’s our location?",
          a: "Our store is located at Kepong. Just search Aitsulab Enterprise on Waze and you can find it.",
        },
        {
          q: "Do you have a showroom we can visit?",
          a: "Yes, we do have a showroom upstairs of our store. Waze Aitsulab Enterprise or here's our address:2383, Jalan E3/9, Taman Ehsan, 52100 Kepong, Kuala Lumpur",
        },
        {
          q: "Do you provide free delivery and free installation?",
          a: "We do provide free delivery in West Malaysia and free installation in Klang Valley area",
        },
        {
          q: "What's the warranty provided? and how many years?",
          a: "We provide 5 years warranty for all the chair parts excluding mesh/ cushion part. For mesh/ cushion part we providing 3 years warranty.",
        },
        {
          q: "How do I assemble my chair?",
          a: "There's a manual inside the box. All our chairs are very simple to assemble. You can also take E-manual from us if you need.",
        },
      ],
    };
  },
};
</script>

<style>
.icon-badge {
  width: 5rem !important;
}

@media (min-width: 768px) {
  .hCustom {
    height: 27rem;
  }
}
@media (min-width: 1023px) {
  .hCustom {
    height: 29rem;
  }
}
</style>
