<template>
  <div>
    <!-- <div
      class="absolute w-full h-full bg-center bg-no-repeat bg-cover md:hidden"
      style="background-image: url('/images/bg-banner.jpg')"
    ></div> -->
    <div class="md:hidden">
      <!-- <a href="#" v-scroll-to="{ el: '#product', duration: 1500 }"> -->
      <img src="/images/banner-mobile.jpg" alt="" />
      <!-- </a> -->
    </div>
    <div class="hidden md:block">
      <!-- <a href="#" v-scroll-to="{ el: '#product', duration: 1500 }"> -->
      <img src="/images/bg-banner.jpg" alt="" />
      <!-- </a> -->
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
